import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./styles.css";
import logo from "../../assets/logo.svg";
import LayoutIconTextRow from "../LayoutIconTextRow";

export default class Contact extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={"footer"}>
        <Container>
          <Row>
            <Col xs="12" md="12">
              <LayoutIconTextRow
                content={{
                  column: this.props.content || [],
                  title: this.props.title
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="12">
              <div className={"wrapper"}>
                © {new Date().getFullYear()} Sea Cucumber. Todos os direitos
                reservados, developed in collaboration with{" "}
                <a href="https://cors.digital/"> CORS Digital</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
