import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { Button, ButtonGroup } from "reactstrap";
import ReactGA from "react-ga";
import "./styles.css";

class GDPR extends Component {
  constructor(props) {
    super(props);

    let gdpr = {
      analytics: true, // accept analtics
      set: false, // if we prompt or not
      google: null
    };

    if (typeof localStorage !== "undefined") {
      gdpr = JSON.parse(localStorage["GDPR"] || null) || gdpr;
    }

    this.state = {
      settings: false,
      policy: false,
      privacy: false,
      gdpr
    };

    this.onToggleModal = this.onToggleModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onAccept = this.onAccept.bind(this);

    this.onInitTracking();
  }

  onInitTracking() {
    this.onInitAnalytics();
  }

  onInitAnalytics() {
    if (typeof window !== "undefined" && this.state.gdpr.analytics) {
      // ReactGA.initialize('UA-1075237-26');
      ReactGA.initialize(this.state.google);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  onToggleModal(what) {
    this.setState({ [what]: !this.state[what] });
  }

  onAccept(what, value) {
    const gdpr = {
      ...this.state.gdpr,
      [what]: value || !this.state.gdpr[what]
    };
    this.setState({ gdpr });

    if (typeof localStorage !== "undefined") {
      localStorage["GDPR"] = JSON.stringify(gdpr);
    }

    if (what === "set") {
      this.onInitTracking();
    }
  }

  onCloseModal() {
    this.setState({
      settings: false,
      policy: false,
      privacy: false
    });
  }

  render() {
    const { settings, policy, privacy } = this.state;
    if (!this.props.content) return null;
    return (
      !this.state.gdpr.set && (
        <div className={"GDPR"}>
          <div className={"footer-note"}>
            <div className={"alert-text"}>
              {this.props.content.footer_text}{" "}
              <a
                className={"cookie-policy"}
                onClick={() => this.onToggleModal("policy")}
              >
                {this.props.content.cookie_policy_button}
              </a>
            </div>
            <div className={"alert-actions"}>
              {this.props.content.cookie_settings_button && (
                <Button
                  outline
                  color="info"
                  className={"setting-button"}
                  onClick={() => this.onToggleModal("settings")}
                >
                  {this.props.content.cookie_settings_button}
                </Button>
              )}{" "}
              {this.props.content.accept_cookies_button && (
                <Button color="success" onClick={() => this.onAccept("set")}>
                  {this.props.content.accept_cookies_button}
                </Button>
              )}
            </div>
          </div>

          <Modal
            open={settings}
            onClose={this.onCloseModal}
            center
            classNames={{
              modal: "custom-modal",
              overlay: "custom-overlay"
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.content.cookie_policy
              }}
            />

            {this.state.google && (
              <ul>
                <li>
                  Google Analytics{" "}
                  <ButtonGroup>
                    <Button
                      color="primary"
                      onClick={() => this.onAccept("analytics", true)}
                      active={this.state.gdpr.analytics}
                      outline
                    >
                      Active
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={() => this.onAccept("analytics", false)}
                      active={!this.state.gdpr.analytics}
                    >
                      Inactive
                    </Button>
                  </ButtonGroup>
                </li>
              </ul>
            )}

            {/* <h3>Strictly Necessary Cookies</h3>

          <p>
            These cookies are necessary for the website to function and cannot
            be switched off in our systems. They are usually only set in
            response to actions made by you which amount to a request for
            services, such as setting your privacy preferences, logging in or
            filling in forms. You can set your browser to block or alert you
            about these cookies, but some parts of the site may not work then.
          </p> */}
          </Modal>

          <Modal
            open={policy}
            onClose={this.onCloseModal}
            center
            classNames={{
              modal: "custom-modal",
              overlay: "custom-overlay"
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.content.cookie_terms
              }}
            />
          </Modal>
        </div>
      )
    );
  }
}

export default GDPR;
