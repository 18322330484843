import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./styles.css";
import { setLanguage } from "./actions";
import config from "../../../config";

class Language extends Component {
  constructor(props) {
    super(props);
    this.toggleLanguage = this.toggleLanguage.bind(this);
  }

  toggleLanguage(lang) {
    this.props.setLanguage(lang);
  }

  getThisPath(lang) {
    let location = "/";

    // add language
    if (lang !== config.defaultLang) {
      location += `${lang}/`;
    } else {
      location += ``;
    }

    // add path
    if (this.props.location) {
      location += this.props.location
        .split("/") // deconstruct url
        .filter(path => path !== "" && this.props.lang !== path) // filter what we need
        .join("/"); // reconstrcut
    }

    return location;
  }

  render() {
    return (
      <ul className={"lang"}>
        {config.languages.map((lang, i) => {
          return (
            <li key={i}>
              <a
                href={this.getThisPath(lang)}
                // onClick={() => this.toggleLanguage(lang)}
                className={this.props.lang === lang ? "active" : ""}
              >
                {lang}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
}

export default connect(
  state => {
    return {
      lang: state.language
    };
  },
  { setLanguage }
)(Language);
