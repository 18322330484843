import React from "react";
import { Container, Row, Col } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./styles.css";
import Title from "../Title";

const Show = props => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

function RenderItem(icon, row) {
  return (
    <div className={"content-wrap"}>
      {icon && (
        <div className={"image"}>
          <img src={icon.sizes && icon.sizes.large} />
        </div>
      )}
      <div className={"text"}>
        <h3>{row.title}</h3>
        <p dangerouslySetInnerHTML={{ __html: row.content }} />
      </div>
    </div>
  );
}
function LayoutIconTextRow(props) {
  const { column } = props.content;
  return (
    <div className={`icon-row ${props.first ? "first" : ""}`}>
      <Title>{props.content.title}</Title>
      <Container>
        <Row>
          {column.map((row, i) => {
            const icon = row.icon || (row.partner && row.partner.image);
            const link = row.link || (row.partner && row.partner.link);
            return (
              <Show key={i}>
                <Col>
                  {/* <Col className={"align-items-center align-self-center "}> */}
                  <ScrollAnimation animateIn="fadeInUp" animateOut="fadeOut">
                    {link ? (
                      <a href={link} target="_blank">
                        {RenderItem(icon, row)}
                      </a>
                    ) : (
                      RenderItem(icon, row)
                    )}
                  </ScrollAnimation>
                </Col>
              </Show>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default LayoutIconTextRow;
