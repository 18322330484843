// The basics
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { frontloadConnect } from "react-frontload";
import LoadingBar from "react-redux-loading-bar";

// Action creators and helpers
// import { establishCurrentUser } from '../modules/auth';
import { getMenus } from "./containers/Menus/actions";
import { getOptions } from "./containers/Options/actions";
import { isServer } from "../store";
import { setLanguage } from "./containers/Language/actions";
import GDPR from "./components/GDPR";

// import Header from './header';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Routes from "./routes";

import config from "../config";

import "normalize.css/normalize.css";
import "animate.css/animate.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import "slick-carousel/slick/slick.css";

import "./app.css";

const frontload = async props => {
  return Promise.all([
    props.setLanguage(props.match.params.lang),
    props.getMenus(props.match.params.lang),
    props.getOptions(props.match.params.lang)
  ]);
};

class App extends Component {
  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      var browserUpdate = require("browser-update");

      browserUpdate({
        required: {
          e: -2,
          i: 11,
          f: -3,
          o: -3,
          s: 10.1,
          c: "64.0.3282.16817",
          samsung: 7.0,
          vivaldi: 1.2
        },
        insecure: true
      });
    }
  }
  componentWillMount() {
    // this.props.getMenus();
    // if (!isServer) {
    //   this.props.establishCurrentUser();
    // }
  }

  isthishomepage() {
    if (
      this.props.location.pathname === "/" ||
      this.props.location.pathname === "/index.html"
    ) {
      return true;
    } else {
      const lang = config.languages.find(lang => {
        return (
          this.props.location.pathname === `/${lang}` ||
          this.props.location.pathname === `/${lang}/`
        );
      });
      return lang ? true : false;
    }
  }

  getCurrentPageSlug() {
    const totalSlug = this.props.location.pathname
      .split("/")
      .filter(part => part !== "");

    return totalSlug && totalSlug.join(" ");
  }

  render() {
    return (
      <div
        id="app"
        className={`${this.getCurrentPageSlug()} ${
          this.props.contentType ? this.props.contentType : "page"
        } ${this.isthishomepage() ? "home" : "nothome"}`}
      >
        <LoadingBar style={{ zIndex: 999, backgroundColor: "#ffcd00" }} />
        <Header
          isAuthenticated={this.props.isAuthenticated}
          options={this.props.options}
          current={this.props.location.pathname}
          isHome={this.isthishomepage()}
          lang={this.props.match.params.lang}
        />
        <div id="content">
          <Routes />
        </div>
        <GDPR content={this.props.gdpr} />
        <Footer
          current={this.props.location.pathname}
          title={this.props.content.content && this.props.content.content.text}
          content={
            this.props.content.content
              ? this.props.content.content.Partners
              : []
          }
          isHome={this.isthishomepage()}
          lang={this.props.match.params.lang}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  content: state.options.options,
  options: state.options.options.options,
  gdpr:
    state.options && state.options.options.gdrp
      ? state.options.options.gdrp
      : {},
  contentPage: state.content.currentContent
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getMenus, getOptions, setLanguage }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    frontloadConnect(frontload, {
      onMount: true,
      onUpdate: false
    })(App)
  )
);
